<template>
  <div>
    <!-- 타이틀  -->
    <div class="title_wrap">
      <h1>로케이션/품목별 재고현황</h1>
    </div>
    <!-- 검색 영역 -->
    <common-area-search
        :row="3"
        :colspan="2"
    >
      <template v-slot:R1_C1>
        <label>회사명</label>
        <ul>
          <common-multi-drop-down
              ref="cmpyCdRef"
              :value="$props.defaultCmpyCd"
              @change="value=>{
                propsCmpyCd = value
                propsLocaCd = null
                $refs.locaCdRef.widget().value('')
                $refs.itemCdRef.widget().value('')
                commonOnChangeEvent()
                if(value === '' || !value){
                  stkSetDataSource = []
                }
              }"
              :params="{searchCmpyGbnGrup : [Constant.efs.cmpyGbn.CLIENT_COMPANY]}"
              :data-columns="[{ field: 'cmpyNm', title: '회사명', width: '60%' },{ field: 'cmpyCd', title: '회사코드', width: '40%' }]"
              :url="`${ApiConfig.efsDomain}/efs/drop-down/company`"
              :data-value-field="'cmpyCd'"
              :data-text-field="'cmpyNm'"
          >
          </common-multi-drop-down>
        </ul>
      </template>
      <template v-slot:R2_C1>
        <label>로케이션</label>
        <ul>
          <common-multi-drop-down
              ref="locaCdRef"
              :params="{
                  searchCmpyCd : propsCmpyCd,
                  linkCmpyCd : propsCmpyCd,
                  linkLocaCd : '0001'
              }"
              :disabled="!propsCmpyCd || propsCmpyCd === ''"
              :all-params-validator="true"
              :url="`${ApiConfig.efsDomain}/efs/drop-down/location`"
              :data-value-field="'locaCd'"
              :data-text-field="'locaNm'"
              @change="value=>{
                propsLocaCd = value
                commonOnChangeEvent()
                if(value === '' || !value){
                  stkSetDataSource = []
                }
              }"
          >
          </common-multi-drop-down>
        </ul>
      </template>
      <template v-slot:R2_C2>
        <label>품목</label>
        <ul>
          <common-multi-drop-down
              ref="itemCdRef"
              :params="{searchCmpyCd : propsCmpyCd}"
              :disabled="!propsCmpyCd || propsCmpyCd === ''"
              :url="`${ApiConfig.efsDomain}/efs/drop-down/item`"
              :data-value-field="'itemCd'"
              :data-text-field="'itemNm'"
              @change="value=>{
                propsItemCd = value
                commonOnChangeEvent()
                if(value === '' || !value){
                  stkSetDataSource = []
                }
              }"
          >
          </common-multi-drop-down>
        </ul>
      </template>
      <template v-slot:R1_C2>
        <label>기간</label>
        <ul>
          <common-range-datepicker
              ref="dtRef"
              :range="{start : weekAgoDate, end : new Date()}"
              @change="commonOnChangeEvent"
          >
          </common-range-datepicker>
        </ul>
      </template>
      <template #buttonArea>
        <div class="button_wrap_area" style="width:15%!important">
          <ul>
            <li>
              <button class="large_btn orange" @click="commonOnChangeEvent">검색</button>
            </li>
            <li>
              <button class="large_btn" @click="cleanSearchDataEvent">초기화</button>
            </li>
          </ul>
        </div>
      </template>
    </common-area-search>
    <!-- //검색 영역 -->

    <section>
      <div class="contents_top_sec">
        <div class="c_t_button_wrap fr">
          <button class="mid_btn" @click="onClickExcelDownload"><i class="download_icon"></i>엑셀 다운로드</button>
        </div>
      </div>

      <div class="content_list">
        <kendo-grid ref="gridRef"
                    style="cursor: pointer; max-height:450px"
                    :data-source="stkSetDataSource"
                    :navigatable="false"
                    :sortable-mode="'multiple'"
                    :pageable="false"
                    :reorderable="true"
                    :column-menu="false"
                    :resizable="true"
                    :selectable="'row'"
                    :columns="stkSetGridColumns"
                    :noRecords="{ template: '<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>' }"
        />
      </div>
    </section>
  </div>
</template>

<script>
import ApiUtil from '@/api/api.util'
import ApiConfig from '@/api/api.config'
import EtnersDateTimeUtil from '@ebmp-fe-common-module/module/src/common/etners.datetime.util'
import EtnersCommonUtil from '@ebmp-fe-common-module/module/src/common/etners.common.util'
import { saveExcel } from '@progress/kendo-vue-excel-export'
import { mapGetters } from 'vuex'
import Constant from '@/common/constant'

export default {
  name: 'StkLocationList',
  props : {
    defaultCmpyCd : {
      type : String,
      required : false,
      default: ''
    }
  },
  computed :{
    ...mapGetters(['loginUserData','linkLocaCd','linkCmpyCd']),
  },
  created: function () {
    const vm = this
    vm.propsCmpyCd = vm.$props.defaultCmpyCd
    vm.getSetItemCdList()
  },
  methods: {
    getItemCdList: function () {
      const vm = this

      let param = vm.searchFilterParam()
      if(!param || !vm.propsCmpyCd){
        vm.lastRouteParams = {}
        return []
      }else {
        vm.lastRouteParams = {
          ...param,
          linkCmpyCd : vm.propsCmpyCd,
          linkLocaCd : "0001"
        }
      }
      ApiUtil.query(`${ApiConfig.fmsDomain}/fms/stk/loca`, vm.lastRouteParams)
      .then((response) => {
        if (
            response.data === undefined ||
            response.data.resultStatus === undefined
        ) {
          kendo.alert("데이터가 정상적으로 조회되지 않았습니다.");
          return false;
        }
        if (response.data.resultStatus.messageCode === "4042") {
          vm.$refs.gridRef.kendoWidget().setDataSource(new kendo.data.DataSource())
          return false;
        }

        if (response.data.resultStatus.messageCode !== "2000") {
          vm.$refs.gridRef.kendoWidget().setDataSource(new kendo.data.DataSource())
          kendo.alert(response.data.resultStatus.messageText);
          return false;
        }
        vm.stkSetDataSource = response.data.resultData
      })
    },
    cleanSearchDataEvent: function (ev) {
      const refs = this.$refs

      /**
       * 회사, 품목, 세트, 등록일자
       * */
      refs.cmpyCdRef.widget().value('')
      refs.itemCdRef.widget().value('')
      refs.locaCdRef.widget().value('')
      this.propsCmpyCd = null
      this.propsSet = null
      let initDate= {start : this.weekAgoDate, end : new Date()}
      refs.dtRef?.widget().range(initDate)

      this.$refs.gridRef.kendoWidget().setDataSource(new kendo.data.DataSource())

      this.commonOnChangeEvent()

    },
    searchFilterParam: function () {
      let searchFilterOptions = {}
      const refs = this.$refs

      const cmpyCd = refs.cmpyCdRef?.widget().value()
      if (cmpyCd) {searchFilterOptions.searchCmpyCd = cmpyCd}
      else { return null }

      const itemCd = refs.itemCdRef?.widget().value()
      if (itemCd) {searchFilterOptions.searchItemCd = itemCd}
      else { return null }

      const dtRefValue = refs.dtRef?.widget()?.range()
      if (dtRefValue) {
        const searchStartDt = dtRefValue.start
            ? EtnersDateTimeUtil.dateToYYYYMMDD(dtRefValue.start) : ''
        const searchEndDt = dtRefValue.end
            ? EtnersDateTimeUtil.dateToYYYYMMDD(dtRefValue.end) : ''
        if(!searchStartDt || !searchEndDt){
          return null
        }
        searchFilterOptions.searchStartDt = searchStartDt
        searchFilterOptions.searchEndDt = searchEndDt
      }
      else { return null }

      const locaCd = refs.locaCdRef?.widget().value()
      if (locaCd) {searchFilterOptions.searchLocaCd = locaCd}
      else { return null }

      return searchFilterOptions ?? undefined
    },
    commonOnChangeEvent: function (e) {
      const vm = this
      vm.getItemCdList()
    },
    onClickExcelDownload: function(e) {

      const vm = this
      vm.lastRouteParams.requestType = 'EXPORT'
      ApiUtil.query(`${ApiConfig.fmsDomain}/fms/stk/set`, vm.lastRouteParams).then(response => {

        if (response.data.resultStatus.messageCode === '2000') {
          let resultData = response.data.resultData
          if(EtnersCommonUtil.isNotEmpty(resultData) && resultData.length > 0) {

            let stkSetGridColumns = vm.stkSetGridColumns.map(item=>{
              item.headerCellOptions = { textAlign: 'center', borderLeft: '1px', borderRight: '1px' }
              item.width = '80px'
              if (item.columns){
                item.children = item.columns
                item.children.forEach((child, i)=>{
                  child.headerCellOptions = { textAlign: 'center', borderLeft: i === 0 ? '1px' : '' }
                  child.width = '50px'
                })
              }
              return item
            })

            saveExcel({
              data: resultData,
              fileName: '세트품목별재고목록_' + EtnersDateTimeUtil.dateToYYYYMMDD(new Date()) + '.xlsx',
              columns: stkSetGridColumns
            })
          } else {
            kendo.alert('다운로드 할 자료가 없습니다.')
          }
        } else {
          kendo.alert( response.data.resultStatus.messageText)
          return false
        }
      })
    },
    getSetItemCdList: function () {
      const vm = this
      let param = {
        linkCmpyCd : vm.linkCmpyCd,
        linkLocaCd : vm.linkLocaCd
      }
      ApiUtil.query(`${ApiConfig.fmsDomain}/fms/goods/item/set`, param)
      .then((response) => {
        if (
            response.data === undefined ||
            response.data.resultStatus === undefined
        ) {
          kendo.alert("데이터가 정상적으로 조회되지 않았습니다.");
          return false;
        }
        if (response.data.resultStatus.messageCode !== "2000") {
          kendo.alert(response.data.resultStatus.messageText);
          return false;
        }
        vm.itemCdDataArray = response.data.resultData
      })
      .catch((error) => {
        console.log(error);
      });
    },
  },
  data () {
    const headerAttributes = {
      style: 'font-weight: bold; text-align: center;',
    }
    const centerAttributes = { style: 'text-align: center;' }
    const rightAttributes = { style: 'text-align: right;' }

    let now = new Date()
    let weekAgo = new Date()
    weekAgo.setDate(weekAgo.getDate() - 6)

    return {
      Constant,
      endDt : null,
      ApiConfig,
      propsCmpyCd : '',
      propsLocaCd : '',
      lastRouteParams : {},
      stkSetGridColumns: [
        {
          field: 'dt',
          title: '일자',
          width: '7%',
          headerAttributes : {
            'class': 'bd_r f_b',
          },
          attributes: centerAttributes,
        },
        {
          field: "itemNm",
          title: "품목명",
          width: "10%",
          headerAttributes : {
            'class': 'bd_r f_b',
          },
          attributes: centerAttributes
        },
        {
          title: "신규상품",
          headerAttributes : {
            'class': 'bd_r f_b',
          },
          columns : [
            { field: "newGoodsBeginCnt",
              title: "기초",
              format: "{0:###,#}",
              width: "7%",
              headerAttributes: headerAttributes,
              attributes: rightAttributes
            },
            { field: "newGoodsinCnt",
              title: "입고",
              format: "{0:###,#}",
              width: "7%",
              headerAttributes: headerAttributes,
              attributes: rightAttributes
            },
            { field: "newGoodsOutCnt",
              title:"출고",
              format: "{0:###,#}",
              width: "7%",
              headerAttributes: headerAttributes,
              attributes: rightAttributes
            },
            { field: "newGoodsLossCnt",
              title:"분실",
              format: "{0:###,#}",
              width: "7%",
              headerAttributes: headerAttributes,
              attributes: rightAttributes
            },
            { field: "newGoodsDisuseCnt",
              title:"폐기",
              format: "{0:###,#}",
              width: "7%",
              headerAttributes: headerAttributes,
              attributes: rightAttributes
            },
            { field: "newGoodsEndCnt",
              title: "기말",
              format: "{0:###,#}",
              width: "7%",
              headerAttributes : {
                'class': 'bd_r f_b',
              },
              attributes: rightAttributes
            },
          ]
        },

        {
          title: "재생상품",
          width: "7%",
          headerAttributes : {
            'class': 'bd_r f_b',
          },
          columns : [
            { field: "renewGoodsBeginCnt",
              title: "기초",
              format: "{0:###,#}",
              width: "7%",
              headerAttributes: headerAttributes,
              attributes: rightAttributes
            },
            { field: "renewGoodsinCnt",
              title:"입고",
              format: "{0:###,#}",
              width: "7%",
              headerAttributes: headerAttributes,
              attributes: rightAttributes
            },
            { field: "renewGoodsOutCnt",
              title:"출고",
              format: "{0:###,#}",
              width: "7%",
              headerAttributes: headerAttributes,
              attributes: rightAttributes
            },
            { field: "renewGoodsLossCnt",
              title:"분실",
              format: "{0:###,#}",
              width: "7%",
              headerAttributes: headerAttributes,
              attributes: rightAttributes
            },
            { field: "renewGoodsDisuseCnt",
              title:"폐기",
              format: "{0:###,#}",
              width: "7%",
              headerAttributes: headerAttributes,
              attributes: rightAttributes
            },
            { field: "renewGoodsEndCnt",
              title:"기말",
              format: "{0:###,#}",
              width: "7%",
              headerAttributes: headerAttributes,
              attributes: rightAttributes
            },
          ]
        },
      ],
      weekAgoDate : weekAgo,
      stkSetDataSource : [],
      itemCdDataArray : [],
    }
  },
}
</script>
