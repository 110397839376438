var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('common-area-search',{attrs:{"row":3,"colspan":2},scopedSlots:_vm._u([{key:"R1_C1",fn:function(){return [_c('label',[_vm._v("회사명")]),_c('ul',[_c('common-multi-drop-down',{ref:"cmpyCdRef",attrs:{"value":_vm.$props.defaultCmpyCd,"params":{searchCmpyGbnGrup : [_vm.Constant.efs.cmpyGbn.CLIENT_COMPANY]},"data-columns":[{ field: 'cmpyNm', title: '회사명', width: '60%' },{ field: 'cmpyCd', title: '회사코드', width: '40%' }],"url":((_vm.ApiConfig.efsDomain) + "/efs/drop-down/company"),"data-value-field":'cmpyCd',"data-text-field":'cmpyNm'},on:{"change":function (value){
              _vm.propsCmpyCd = value
              _vm.propsLocaCd = null
              _vm.$refs.locaCdRef.widget().value('')
              _vm.$refs.itemCdRef.widget().value('')
              _vm.commonOnChangeEvent()
              if(value === '' || !value){
                _vm.stkSetDataSource = []
              }
            }}})],1)]},proxy:true},{key:"R2_C1",fn:function(){return [_c('label',[_vm._v("로케이션")]),_c('ul',[_c('common-multi-drop-down',{ref:"locaCdRef",attrs:{"params":{
                searchCmpyCd : _vm.propsCmpyCd,
                linkCmpyCd : _vm.propsCmpyCd,
                linkLocaCd : '0001'
            },"disabled":!_vm.propsCmpyCd || _vm.propsCmpyCd === '',"all-params-validator":true,"url":((_vm.ApiConfig.efsDomain) + "/efs/drop-down/location"),"data-value-field":'locaCd',"data-text-field":'locaNm'},on:{"change":function (value){
              _vm.propsLocaCd = value
              _vm.commonOnChangeEvent()
              if(value === '' || !value){
                _vm.stkSetDataSource = []
              }
            }}})],1)]},proxy:true},{key:"R2_C2",fn:function(){return [_c('label',[_vm._v("품목")]),_c('ul',[_c('common-multi-drop-down',{ref:"itemCdRef",attrs:{"params":{searchCmpyCd : _vm.propsCmpyCd},"disabled":!_vm.propsCmpyCd || _vm.propsCmpyCd === '',"url":((_vm.ApiConfig.efsDomain) + "/efs/drop-down/item"),"data-value-field":'itemCd',"data-text-field":'itemNm'},on:{"change":function (value){
              _vm.propsItemCd = value
              _vm.commonOnChangeEvent()
              if(value === '' || !value){
                _vm.stkSetDataSource = []
              }
            }}})],1)]},proxy:true},{key:"R1_C2",fn:function(){return [_c('label',[_vm._v("기간")]),_c('ul',[_c('common-range-datepicker',{ref:"dtRef",attrs:{"range":{start : _vm.weekAgoDate, end : new Date()}},on:{"change":_vm.commonOnChangeEvent}})],1)]},proxy:true},{key:"buttonArea",fn:function(){return [_c('div',{staticClass:"button_wrap_area",staticStyle:{"width":"15%!important"}},[_c('ul',[_c('li',[_c('button',{staticClass:"large_btn orange",on:{"click":_vm.commonOnChangeEvent}},[_vm._v("검색")])]),_c('li',[_c('button',{staticClass:"large_btn",on:{"click":_vm.cleanSearchDataEvent}},[_vm._v("초기화")])])])])]},proxy:true}])}),_c('section',[_c('div',{staticClass:"contents_top_sec"},[_c('div',{staticClass:"c_t_button_wrap fr"},[_c('button',{staticClass:"mid_btn",on:{"click":_vm.onClickExcelDownload}},[_c('i',{staticClass:"download_icon"}),_vm._v("엑셀 다운로드")])])]),_c('div',{staticClass:"content_list"},[_c('kendo-grid',{ref:"gridRef",staticStyle:{"cursor":"pointer","max-height":"450px"},attrs:{"data-source":_vm.stkSetDataSource,"navigatable":false,"sortable-mode":'multiple',"pageable":false,"reorderable":true,"column-menu":false,"resizable":true,"selectable":'row',"columns":_vm.stkSetGridColumns,"noRecords":{ template: '<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>' }}})],1)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title_wrap"},[_c('h1',[_vm._v("로케이션/품목별 재고현황")])])}]

export { render, staticRenderFns }